import React, { useEffect, useRef, useState } from 'react';
import VideoChatComponent from './VideoChatComponent';
import getParameterByName from './utils/getParameterByName';
import VideoChatController from './VideoChatController';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { doPeerConnection } from './utils/webcamUtils';
import { AlertDialog } from './GenericDialog';
import {
  terminateCall,
  getIceCandidatesQueue,
  emptyIceCandidatesQueue,
} from './utils/webcamUtils';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import Draggable from 'react-draggable';
import psl from 'psl';
import { useStore } from 'store/storeUtils';
import { observer } from 'mobx-react';
import {
  deleteCallCookie,
  getCallCookie,
  updateCallCookie,
} from './utils/VideocallCookieUtils';
import { VIDEO_CALL_SIZES } from './utils/videocallConsts';
import clsx from 'clsx';
import { FULLSCREEN_EVENT, SYNC_SIZE } from './utils/videocallEvents';
import { useFullScreen } from 'react-browser-hooks/lib/hooks/fullscreen';
import { isMobile } from 'react-device-detect';
import { useTranslation } from '@tecma/i18n';
import ToolLink from 'utils/ToolLink';

export let socket;

let enableRemoteClick = false;
let cookieRefresher = null;

const ClientComponent = observer(() => {
  const [peerConnection, setPeerConnection] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isCallAvailable, setIsCallAvailable] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [isCallingOnProgress, setIsCallingOnProgress] = useState(false);
  const [isCallRejected, setIsCallRejected] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const [isCallTerminated, setIsCallTerminated] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const [videochatInfoMessage, setVideochatInfoMessage] = useState(null);
  const [videoContainerSize, setVideoContainerSize] = useState(
    VIDEO_CALL_SIZES[0]
  );
  const [videoContainerFullscreen, setVideoContainerFullscreen] =
    useState(false);
  const [reconnectionTimeout, setReconnectionTimeout] = useState('');
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const fs = useFullScreen();
  const { t } = useTranslation();

  const clientLoginCheckInterval = useRef();
  const isOnCallingRef = useRef(false);
  const reconnectionInterval = useRef(null);
  const isCallTerminatedRef = useRef(false);

  const roomId = useRef(getParameterByName('roomId'));
  const isVendor = useRef(getParameterByName('isVendor'));
  const clientId = useRef(getParameterByName('clientId'));
  const landingJwt = useRef(getParameterByName('jwt'));

  const history = useHistory();
  const store = useStore();

  const callCookie = getCallCookie();

  const checkClientFullscreen = false;

  const videoCallUrl = `${document.location.origin}?roomId=${
    roomId.current
  }&clientId=${clientId.current}${
    isVendor.current ? '&isVendor=true' : ''
  }&jwt=${landingJwt.current}`;

  const neurosalesVideoCallUrl = ToolLink('neurosales', store, {
    pathToRemove: '/login',
    params: `roomId=${roomId.current}&clientId=${clientId.current}${
      isVendor.current ? '&isVendor=true' : ''
    }`,
  });

  const startCookieRefresh = (roomId, isVendor, clientId) => {
    console.log('startCookieRefresh');

    updateCallCookie(roomId, isVendor, clientId);
    const interval = setInterval(
      () => updateCallCookie(roomId, isVendor, clientId),
      30 * 1000
    );
    return interval;
  };

  const stopCookieRefresh = () => {
    console.log('stopCookieRefresh');
    // Stoppo l'aggiornamento del cookie e lo cancello
    clearInterval(cookieRefresher);
    deleteCallCookie();
  };

  const callClient = async () => {
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(offer));
    socket.emit('call-user', {
      roomId: roomId.current,
      offer,
    });

    setIsCalling(true);
    // Cookie Refresher del VENDOR
    cookieRefresher = startCookieRefresh(
      roomId.current,
      isVendor.current,
      clientId.current
    );
  };

  const confirmCall = async () => {
    await peerConnection.setRemoteDescription(
      new RTCSessionDescription(isCalled)
    );

    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(new RTCSessionDescription(answer));

    socket.emit('make-answer', {
      roomId: roomId.current,
      answer,
    });

    setIsCallingOnProgress(true);
    isOnCallingRef.current = true;
    // Cookie Refresher del CLIENT
    cookieRefresher = startCookieRefresh(
      roomId.current,
      isVendor.current,
      clientId.current
    );

    setTimeout(() => {
      let iceCandidatesQueue = getIceCandidatesQueue();
      if (iceCandidatesQueue.length > 0) {
        let tempIcsCandidatesQueue = [...iceCandidatesQueue];
        emptyIceCandidatesQueue();
        tempIcsCandidatesQueue.forEach(candidate => {
          peerConnection.addIceCandidate(candidate).catch(e => console.log(e));
        });
      }
    }, 1000);

    setIsCalled(false);
  };

  const handleReconnectionInterval = () => {
    setReconnectionTimeout(el => {
      console.log(el);
      if (el === 0) {
        clearInterval(reconnectionInterval.current);
        window.location.replace(neurosalesVideoCallUrl);
        return 0;
      }

      return el - 1;
    });
  };

  useEffect(() => {
    // Se atterro con un url che contiene il parametro "hostname", cancello i cookie
    const landingUrl = window.document.location.href;
    let domain = 'localhost';
    if (window.location.hostname !== 'localhost') {
      const pslUrl = psl.parse(window.location.hostname);
      domain = pslUrl.domain;
    }

    if (landingUrl.includes('hostname') && roomId.current) {
      try {
        // Elimino i cookie JWT e vendorID
        Cookies.remove('jwt', { domain: domain });
        Cookies.remove('vendorId', { domain: domain });
      } catch (e) {
        console.log(e);
      }
    }

    if (roomId.current) {
      store.setVideocallMode(true);

      const cookieOptionsSet = {
        expires: 1, // durata di 1 giorno, se non viene messa la durata diventa un session cookie che viene eliminato dopo aver chiuso il browser
        domain: domain,
      };

      // Reset delle info che saranno impostate
      sessionStorage.removeItem('jwt');
      sessionStorage.removeItem('vendor');
      sessionStorage.removeItem('client');

      if (isVendor.current) {
        // Se venditore, controllo che sia effettivamente loggato sui cookie di dominio
        // e imposto nel sessionStorage ciò che è richiesto per Floorplannig
        if (Cookies.get('jwt')) {
          sessionStorage.setItem('jwt', Cookies.get('jwt'));
          sessionStorage.setItem('vendor', Cookies.get('vendorId'));
          sessionStorage.setItem('client', clientId);

          Cookies.set('clientId', clientId.current, cookieOptionsSet);
        } else {
          // Se non esiste il JWT il vendor non è loggato e lo rimando alla login
          // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo
          const backToUrl = new URL(window.location.href);
          const params = new URLSearchParams(backToUrl.search);
          params.delete('hostname');
          const backTo = `${window.location.origin}${window.location.pathname}?${params}`;

          window.location.href = ToolLink('businessplatform', store, {
            pathToRemove: '/login',
            path: '/login',
            params: 'backTo=' + encodeURIComponent(backTo),
          });
        }

        // Gestione degli input da tastiera
        // Utilizzo l'attributo "data-name" per sincronizzare i dati tra vendor e client
        // Sincronizza solo se gli elementi sono input text e sono contenuti da un elemento padre
        // con classe .videocall-sync-input
        const syncInputData = e => {
          // Per evitare modifiche alle checkbox e radio nel caso si prema il tasto tab
          if (e.keyCode === 9) {
            return null;
          }

          const target = e.target;
          const syncInputValue =
            target && target.closest('.videocall-sync-input');
          console.log(e);

          const isInput =
            target.nodeName &&
            (target.nodeName.toLowerCase() === 'input' ||
              target.nodeName.toLowerCase() === 'select');

          if (syncInputValue && isInput) {
            const dataToSync = {
              value: target.value,
              type: target.type,
              id: target.id,
              name: target.name,
              checked: target.checked,
              dataName: target.getAttribute('data-name'),
            };

            console.log(dataToSync);

            socket.emit('send-input-value', {
              roomId: roomId.current,
              event: dataToSync,
            });
          }
        };

        document.addEventListener('keyup', syncInputData, true);

        document.addEventListener('click', syncInputData, true);

        const body = document.body;
        body.classList.add('i-am-the-vendor');
        store.setVideocallIsVendor(true);
      } else {
        // Se non è venditore imposto nel sessionStorage un fake vendorId
        // e il clientId reale passato nell'url

        console.log('CLIENT MODE');
        sessionStorage.setItem('vendor', 'fake');
        sessionStorage.setItem('client', clientId);
        sessionStorage.setItem('videocallMode', true);

        Cookies.set('vendorId', 'fake', cookieOptionsSet);
        Cookies.set('clientId', clientId.current, cookieOptionsSet);

        const body = document.body;
        body.classList.add('i-am-the-client');

        const handleClientMouseEvent = e => {
          const elWithClickEnablerClass =
            e.target.closest('.videochat-client-mouse-enabled') ||
            e.target.closest('.click-always-enabled') ||
            e.target.closest('.drag-here');
          console.log(elWithClickEnablerClass);
          if (elWithClickEnablerClass) {
            return null;
          }
          if (!enableRemoteClick) {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }
        };

        // Gestione dell'evento CLICK del CLIENTE in VIDEOCHAT
        document.addEventListener('click', handleClientMouseEvent, true);
        // Gestione dell'evento MOUSEDOWN del CLIENTE in VIDEOCHAT
        document.addEventListener('mousedown', handleClientMouseEvent, true);

        const handleClientKeyboardEvent = e => {
          const elWithClickEnablerClass = e.target.closest(
            '.videochat-client-keyboard-enabled'
          );

          if (elWithClickEnablerClass) {
            return null;
          }
          if (!enableRemoteClick) {
            e.stopPropagation();
            e.preventDefault();
            return false;
          }
        };

        // Gestione dell'evento KEYDOWN del CLIENTE in VIDEOCHAT
        document.addEventListener('keydown', handleClientKeyboardEvent, true);
      }

      socket = io('https://api-staging.tecmasolutions.com', {
        path: process.env.REACT_APP_WEBSOCKET,
      });

      // FACCIO LISTEN SUL ROUTE HISTORY PER MOSTRARE BOTTONE TORNA IN NEURO SALES
      if (isVendor.current) {
        history.listen(location => {
          if (location.pathname.indexOf('configurator') >= 0) {
            document.querySelector('.backToNeuro').style.display = 'block';
          } else {
            document.querySelector('.backToNeuro').style.display = 'none';
          }
          console.log(`You changed the page to: ${location.pathname}`);
        });
      }

      const handleCallUnavailable = () => {
        if (!socket.connected) {
          setIsCallAvailable(false);
          setIsCallingOnProgress(false);
          setIsCalling(false);

          stopCookieRefresh();

          terminateCall();
        } else {
          // Se il vendor o il client si scollegano, li riporto alla pagina inziale dopo tot secondi

          if (isOnCallingRef.current && !isCallTerminatedRef.current) {
            socket.disconnect();
            terminateCall();
            stopCookieRefresh();
            setIsDisconnecting(true);
            setReconnectionTimeout(5);
            reconnectionInterval.current = setInterval(() => {
              handleReconnectionInterval();
            }, 1000);
          }

          setIsCallAvailable(false);
          setIsCallingOnProgress(false);
          setIsCalling(false);
        }
      };

      const asyncSetPeerConnection = async () => {
        let myPeerConnection = await doPeerConnection(
          socket,
          roomId.current,
          isVendor.current
        );
        setPeerConnection(myPeerConnection);

        let getCalled = false;

        socket.on('call-made', async data => {
          // if (!getCalled) {
          //   setIsCalled(data.offer);
          //   getCalled = true;
          //   console.log('call-made');
          // }

          setIsCalled(data.offer);
        });

        socket.on('call-rejected', () => {
          setIsCalled(false);
          console.log('call-rejected');
          setIsCallRejected(true);
          setIsCallingOnProgress(false);
          setIsCalling(false);

          console.log('call-rejected');

          getCalled = false;
        });

        socket.on('terminated-call', () => {
          setIsCalled(false);
          setIsCallTerminated(true);
          setIsCallingOnProgress(false);
          setIsCalling(false);

          socket.disconnect();

          // Cancello i cookie di chiamata per chi riceve l'evento di chiusura
          stopCookieRefresh();
          console.log('terminated-call');
        });
      };

      socket.on('connect', async () => {
        console.log('connect');
        socket.emit('subscribe', roomId.current);
      });

      socket.on('subscribed', async () => {
        setIsSubscribed(true);
        asyncSetPeerConnection();
      });

      socket.on('call-available', async () => {
        setIsCallAvailable(true);
        console.log('call-available');
      });

      socket.on('call-unavailable', async () => {
        console.log('call-unavailable');
        handleCallUnavailable();
      });

      socket.on('self-answer-made', async data => {
        setIsCallingOnProgress(true);
        isOnCallingRef.current = true;
      });

      //VENDOR
      if (isVendor.current) {
        // Timeout, started on mousedown, triggers the beginning of a hold
        let holdStarter = null;
        // Milliseconds to wait before recognizing a hold
        let holdDelay = 500;
        // Indicates the user is currently holding the mouse down
        let holdActive = false;

        let video = null;

        const getElementClick = el => {
          let eventsList = el.getEventListeners();
          if (
            el.classList &&
            el.classList.value.includes('videocall-stop-event-checking')
          ) {
            return null;
          } else if (el.onclick || eventsList.click) {
            if (document) return el;
          } else {
            if (el.parentElement) return getElementClick(el.parentElement);
            else return null;
          }
        };

        const getElementClickIndexChild = child => {
          let i = 0;
          while (
            child.previousElementSibling &&
            child.previousElementSibling.classList.value ===
              child.classList.value
          ) {
            child = child.previousElementSibling;
            i++;
          }

          return i;
        };

        const addListenerVideoMulti = (el, s, fn) => {
          s.split(' ').forEach(e => el.addEventListener(e, fn, false));
        };

        //GESTIONE CLICK + VIDEO
        window.addEventListener('click', ev => {
          if (ev.target) {
            let elemClick = getElementClick(ev.target);

            //LOGICA DI LOGIN PER FEDRO!
            if (
              elemClick &&
              elemClick.href &&
              elemClick.href.indexOf('home-configurator') >= 0 &&
              elemClick.href.indexOf('login') >= 0
            ) {
              return false;
            }
            if (ev.target.type === 'submit' && ev.target.value === 'login') {
              try {
                let jwt = sessionStorage.getItem('jwt');
                if (jwt) {
                  socket.emit('login-hc-event', {
                    roomId: roomId.current,
                    token: jwt,
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }

            //gestione click con elementi che hanno un listener esplicito del click
            if (elemClick) {
              let classList = elemClick.classList.value;
              classList =
                classList && classList !== ''
                  ? `.${classList
                      .trim()
                      .split(' ')
                      .join('.')
                      .replace('.active', '')}`
                  : null;
              let elemIndex = getElementClickIndexChild(elemClick);
              if (!holdActive) {
                console.log('mouseclick');
                let clickX = ev.clientX; // window.screen.width
                let clickY = ev.clientY; // window.screen.height
                socket.emit('click-event', {
                  roomId: roomId.current,
                  event: {
                    x: clickX,
                    y: clickY,
                    screenWidth: window.screen.width,
                    screenHeight: window.screen.height,
                    classList,
                    elemIndex,
                  },
                });
              }
            }

            // Controllo per FLOORPLANNING
            // Ogni volta che il vendor modifica la quotazione personalizzata,
            // invio un evento al client via socket
            const parent = document.querySelector('div.quotation-sx'); // div.quotation-sx è il div padre del form della quotazione

            let clickDialogueButtonPreventivo = false;
            try {
              clickDialogueButtonPreventivo =
                ev.target &&
                ev.target.className &&
                ev.target.className.includes('button') &&
                ev.target.className.includes('primary-button') &&
                ev.target.parentElement &&
                ev.target.parentElement.className.includes('dialogue');
            } catch (e) {
              console.warn(e);
            }

            if (
              ev.target &&
              parent &&
              parent !== ev.target &&
              (parent.contains(ev.target) || clickDialogueButtonPreventivo)
            ) {
              console.log('sending custom quotation to client');

              // La quotazione si trova nel localStorage customQuote
              socket.emit('quotation-event', {
                roomId: roomId.current,
                event: {
                  quotation: JSON.parse(
                    localStorage.getItem('store_floorplanning')
                  ).customQuote,
                  activePromos: JSON.parse(
                    localStorage.getItem('store_floorplanning')
                  ).activePromos,
                },
              });
            }
          }

          // GESTIONE VIDEO
          setTimeout(() => {
            let videos = document.querySelectorAll(
              'video:not(#local-video):not(#remote-video)'
            );
            if (videos && videos.length > 0) {
              if (!video && !videos[0].isEqualNode(video)) {
                video = videos[0];
                //removed timeupdate
                addListenerVideoMulti(
                  video,
                  'pause play seeked volumechange fullscreenchange webkitfullscreenchange mozfullscreenchange',
                  function (e) {
                    let videoEvent = e.type;
                    socket.emit('video-event', {
                      roomId: roomId.current,
                      event: {
                        type: videoEvent,
                        volume: video.volume,
                        time: video.currentTime,
                      },
                    });
                  }
                );
              }
            }
          }, 1000);
        });

        // GESTIONE DRAG & DROP con mouse
        window.addEventListener('mousedown', ev => {
          // Do not take any immediate action - just set the holdStarter
          //  to wait for the predetermined delay, and then begin a hold
          holdStarter = setTimeout(function () {
            holdStarter = null;
            holdActive = true;
            // begin hold-only operation here, if desired
          }, holdDelay);
        });

        window.addEventListener('mousemove', ev => {
          if (holdActive) {
            let pageX = ev.pageX;
            let pageY = ev.pageY;
            let clientX = ev.clientX;
            let clientY = ev.clientY;
            let screenX = ev.screenX;
            let screenY = ev.screenY;

            socket.emit('move-event', {
              roomId: roomId.current,
              event: {
                pageX,
                pageY,
                clientX,
                clientY,
                screenX,
                screenY,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
              },
            });
          }
        });

        window.addEventListener('mouseup', ev => {
          console.log('mouseup');

          if (holdStarter) {
            clearTimeout(holdStarter);
            // run click-only operation here
          }
          // Otherwise, if the mouse was being held, end the hold
          if (holdActive) {
            holdActive = false;

            let pageX = ev.pageX;
            let pageY = ev.pageY;
            let clientX = ev.clientX;
            let clientY = ev.clientY;
            let screenX = ev.screenX;
            let screenY = ev.screenY;
            socket.emit('move-event', {
              roomId: roomId.current,
              event: {
                pageX,
                pageY,
                clientX,
                clientY,
                screenX,
                screenY,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height,
                type: 'mouseup',
              },
            });
            // end hold-only operation here, if desired
          }
        });

        socket.on('myhome-client-logged', event => {
          setVideochatInfoMessage(
            !event.isLogged ? t('video-chat.client-not-logged') : ''
          );
        });

        socket.on('myhome-proposal-confirmed-by-client', event => {
          store.setSnackbar(true, event.message);
          store.setLoadingSubmitForm(false);

          if (event.isConfirmed) {
            store.setProposalConfirmedByClient(true);
          }
        });

        socket.on('videocall-event', event => {
          try {
            switch (event.name) {
              // Se il client non ha il fullscreen attivato..
              case FULLSCREEN_EVENT:
                console.log('clientFullscreen', event.isFullscreen);

                setVideochatInfoMessage(
                  !event.isFullscreen
                    ? t('video-chat.client-not-fullscreen')
                    : ''
                );

                break;

              default:
                break;
            }
          } catch (e) {
            console.log(e);
          }
        });
      }
      // CLIENT
      else {
        // CLICK
        socket.on('click-event', event => {
          console.log(event.classList);

          if (
            event &&
            event.classList &&
            !event.classList.includes('.videochat-client-noclick') &&
            !event.classList.includes('videochat-no-remote-click')
          ) {
            try {
              let elToClick = document.querySelectorAll(event.classList);
              if (elToClick && elToClick.length > 1) {
                elToClick = document.querySelectorAll(event.classList)[
                  event.elemIndex
                ];
              } else if (elToClick && elToClick.length === 1) {
                elToClick = document.querySelectorAll(event.classList)[0];
              }

              let rect = elToClick.getBoundingClientRect();
              let node = document.createElement('div');
              node.classList.add('dot');
              node.style.top = +rect.top + +rect.height / 2 - 10 + 'px';
              node.style.left = +rect.left + +rect.width / 2 - 10 + 'px';
              document.body.appendChild(node);
              setTimeout(() => {
                node.remove();
              }, 1000);

              // Aggiunto un timeout per il click per far vedere all'utente prima l'animazione
              // di dove si sta cliccando e poi fare l'operazione
              setTimeout(() => {
                enableRemoteClick = true;
                elToClick.click();
                enableRemoteClick = false;
              }, 200);
            } catch (e) {
              console.log(e);
            }
          }
        });

        // DRAG & DROP MOUSE
        let mouseMoving = false;
        let stopMouseMoving = null;
        let elMoving = null;

        socket.on('move-event', event => {
          if (event) {
            if (stopMouseMoving) clearTimeout(stopMouseMoving);
            //document.querySelector('.overlay-protector').style.display = 'none'
            let clientX,
              clientY,
              screenX,
              screenY,
              pageX,
              pageY,
              scaleX,
              scaleY = 0;
            scaleX = event.screenWidth / window.screen.width;
            scaleY = event.screenHeight / window.screen.height;
            scaleX = 1 / scaleX;
            scaleY = 1 / scaleY;

            clientX = event.clientX * scaleX;
            clientY = event.clientY * scaleY;

            screenX = event.screenX * scaleX;
            screenY = event.screenY * scaleY;

            pageX = event.pageX * scaleX;
            pageY = event.pageY * scaleY;

            let videoContainer = document.querySelector('.video-container');
            let elementFromRemote = null;
            let typeEvent = null;

            if (!elMoving) {
              let elementsFromRemote =
                document.elementsFromPoint(clientX, clientY) &&
                document.elementsFromPoint(clientX, clientY).length > 0
                  ? document.elementsFromPoint(clientX, clientY)
                  : null;

              if (elementsFromRemote) {
                for (let el of elementsFromRemote) {
                  let eventsList = el.getEventListeners();
                  if (
                    eventsList.mousemove ||
                    el.onmousemove ||
                    el.mousemove ||
                    eventsList.mousedown ||
                    el.onmousedown ||
                    el.mousedown
                  ) {
                    elementFromRemote = el;
                    typeEvent = 'click';
                    break;
                  }
                }
              }
            } else {
              elementFromRemote = elMoving;
            }

            if (elementFromRemote && typeEvent && videoContainer) {
              if (!mouseMoving) {
                let mousedownEvent = new MouseEvent('mousedown', {
                  view: window,
                  bubbles: true,
                  //'composed': true,
                  clientX,
                  clientY,
                  screenX,
                  screenY,
                  pageX,
                  pageY,
                  cancelable: true,
                });

                elementFromRemote.dispatchEvent(mousedownEvent);
                mouseMoving = true;
              } else {
                //console.log("mousemove")
                let mousemoveEvent = new MouseEvent('mousemove', {
                  view: window,
                  bubbles: true,
                  composed: true,
                  clientX,
                  clientY,
                  screenX,
                  screenY,
                  pageX,
                  pageY,
                  cancelable: true,
                });

                elementFromRemote.dispatchEvent(mousemoveEvent);
              }

              if (stopMouseMoving) clearTimeout(stopMouseMoving);
              if (event.type === 'mouseup') {
                stopMouseMoving = setTimeout(() => {
                  console.log('mouseup');
                  let mouseupEvent = new MouseEvent('mouseup', {
                    view: window,
                    bubbles: true,
                    // 'composed': true,
                    clientX,
                    clientY,
                    screenX,
                    screenY,
                    pageX,
                    pageY,
                    cancelable: true,
                  });
                  mouseMoving = false;
                  elMoving = null;
                  elementFromRemote.dispatchEvent(mouseupEvent);
                }, 100);
              }
            }

            let node = document.createElement('div');
            node.classList.add('dot');
            node.classList.add('dot-move');
            node.style.top = clientY + 'px';
            node.style.left = clientX + 'px';
            document.body.appendChild(node);
            setTimeout(() => {
              node.remove();
            }, 1000);
          }
        });

        //GESTIONE VIDEO
        socket.on('video-event', event => {
          let videos = document.querySelectorAll(
            'video:not(#local-video):not(#remote-video)'
          );
          if (videos && videos.length > 0) {
            let video = videos[0];
            switch (event.type) {
              case 'pause':
                video.pause();
                break;
              case 'play':
                video.play();
                break;
              case 'seeked':
                video.currentTime = event.time;
                break;
              case 'volumechange':
                video.volume = event.volume;
                break;
              case 'fullscreenchange':
              case 'webkitfullscreenchange':
              case 'mozfullscreenchange':
                try {
                  if (video.requestFullscreen) {
                    video.requestFullscreen();
                  } else if (video.mozRequestFullScreen) {
                    /* Firefox */
                    video.mozRequestFullScreen();
                  } else if (video.webkitRequestFullscreen) {
                    /* Chrome, Safari & Opera */
                    video.webkitRequestFullscreen();
                  } else if (video.msRequestFullscreen) {
                    /* IE/Edge */
                    video.msRequestFullscreen();
                  }
                } catch (e) {
                  console.log(e);
                }
                break;
              default:
                console.log('video event not handled');
            }
          }
        });

        //EFFETTUO LOGIN AUTOMATICA PER IL CLIENT!
        socket.on('login-hc-event', newToken => {
          try {
            sessionStorage.setItem('jwt', newToken);
            sessionStorage.setItem('vendor', 'tempVendor');
            sessionStorage.setItem('client', 'tempClient');
            history.push('/home-configurator/');
          } catch (e) {
            console.warn(e);
          }
        });

        const updateInputValue = (input, type, value) => {
          if (type === 'text' || type === 'number') {
            // Modifico il valore in questo modo, per far scatenare l'evento
            // onChange di React
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
              window.HTMLInputElement.prototype,
              'value'
            ).set;
            nativeInputValueSetter.call(input, value);

            const inputEvent = new Event('input', { bubbles: true });
            input.dispatchEvent(inputEvent);
          } else if (type === 'select-one') {
            input.value = value;
          } else if (type === 'checkbox' || type === 'radio') {
            enableRemoteClick = true;
            input.click();
            enableRemoteClick = false;
          } else if (type === 'submit') {
            enableRemoteClick = true;
            input.click();
            enableRemoteClick = false;
          }
        };

        // Gestione valorizzazione input per data-name
        socket.on('receive-input-value', event => {
          console.log(event);
          try {
            const { value, checked, id, name, dataName, type } = event;

            if (id) {
              const inputToSync = document.querySelector(`#${id}`);
              updateInputValue(inputToSync, type, value);
              // inputToSync.scrollIntoView({ behavior: 'smooth' });
            } else if (dataName) {
              const inputsToSync = document.querySelectorAll(
                `[data-name="${dataName}"]`
              );

              inputsToSync.forEach(el => {
                updateInputValue(el, type, value);
              });

              // Solo se è presente un solo elemento scrollo anche la visualizzazione del cliente
              // if (inputsToSync.length === 1) {
              //   inputsToSync[0].scrollIntoView({ behavior: 'smooth' });
              // }
            }
          } catch (e) {
            console.log(e);
          }
        });

        socket.on('videocall-event', event => {
          try {
            switch (event.name) {
              case SYNC_SIZE:
                console.log(SYNC_SIZE);
                setVideoContainerSize(event.size);
                break;

              default:
                break;
            }
          } catch (e) {
            console.log(e);
          }
        });
      }

      return () => {
        socket.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    // Controllo che il cliente in chiamata (non su dispositivo mobile),
    // abbia il dispositivo in fullscreen
    if (
      checkClientFullscreen &&
      !isVendor.current &&
      isCallingOnProgress &&
      !isMobile
    ) {
      if (!fs.fullScreen) {
        socket.emit('videocall-event', {
          roomId: roomId.current,
          event: {
            name: FULLSCREEN_EVENT,
            isFullscreen: false,
          },
        });
      } else {
        socket.emit('videocall-event', {
          roomId: roomId.current,
          event: {
            name: FULLSCREEN_EVENT,
            isFullscreen: true,
          },
        });
      }
    }
  }, [fs]);

  // useEffect(() => {
  //   if (callCookie) {
  //     if (!isCalling && isCallAvailable && isVendor.current && peerConnection) {
  //       setTimeout(() => {
  //         callClient();
  //       }, 1000 * 10);
  //     }
  //   }
  // }, [isCallAvailable, peerConnection]);

  // useEffect(() => {
  //   if (callCookie) {
  //     // In caso di autoconnessione attiva, se sono il cliente e vengo chiamato
  //     // rispondo automaticamente
  //     if (!isCalling && isCalled && !isVendor.current) {
  //       confirmCall();
  //     }
  //   }
  // }, [isCalled]);

  const handleVideoContainerSize = () => {
    const currentSize = videoContainerSize;
    let nextIndex = VIDEO_CALL_SIZES.indexOf(currentSize) + 1;
    nextIndex = nextIndex > VIDEO_CALL_SIZES.length - 1 ? 0 : nextIndex;

    setVideoContainerSize(VIDEO_CALL_SIZES[nextIndex]);

    socket.emit('videocall-event', {
      roomId: roomId.current,
      event: {
        name: SYNC_SIZE,
        size: VIDEO_CALL_SIZES[nextIndex],
      },
    });
  };

  const handleFullscreen = () => {
    setVideoContainerFullscreen(el => !el);
  };

  useEffect(() => {
    if (callCookie) {
      if (!isVendor.current && socket && store.loggedUser) {
        socket.emit('myhome-client-login-event', {
          roomId: roomId.current,
          event: {
            isLogged:
              store.loggedUser && store.loggedUser.client ? true : false,
          },
        });

        clearInterval(clientLoginCheckInterval.current);
      }
    }
  }, [store.loggedUser]);

  useEffect(() => {
    if (roomId.current && !isVendor.current && !store.loggedUser) {
      clientLoginCheckInterval.current = setInterval(() => {
        console.log('Checking login client status');
        socket.emit('myhome-client-login-event', {
          roomId: roomId.current,
          event: {
            isLogged: store.loggedUser ? true : false,
          },
        });
      }, 2000);
    }
  }, []);

  return roomId.current && socket ? (
    <>
      <div id="videochat-draggable-area" />
      {/* Modale per far chiamare il cliente al Vendor */}
      {isVendor.current ? (
        <AlertDialog
          open={
            isCallAvailable &&
            !isCalling &&
            !isDisconnecting &&
            !isCallTerminated
          }
          title="Contatta il Cliente"
          content="Il cliente è disponibile, premi Ok per avviare la Videocall"
          handleConfirm={callClient}
          isConfirm={true}
          confirmText="Ok"
        />
      ) : (
        ''
      )}
      <AlertDialog
        open={isCalled && !isDisconnecting && !isCallingOnProgress}
        title="Richiesta di collegamento"
        content="Il tuo Referente commerciale ha richiesto una connessione video\audio"
        handleConfirm={() => {
          confirmCall();
        }}
        handleClose={() => {
          setIsCalled(false);
          socket.emit('reject-call', {
            roomId: roomId.current,
          });
          socket.disconnect();
          window.location.replace(ToolLink('myhome', store));
        }}
        isConfirm={true}
        confirmText="Accetta"
        closeText="Rifiuta"
      />
      {isVendor.current ? (
        <AlertDialog
          open={isCallRejected ? true : false}
          title="Chiamata rifiutata"
          content="Il cliente ha rifiutato la tua proposta di collegamento"
          handleConfirm={() => {
            setIsCallRejected(false);
          }}
          isConfirm={false}
          confirmText="Ok"
        />
      ) : (
        ''
      )}

      <AlertDialog
        open={isCallTerminated ? true : false}
        title="Chiamata terminata"
        content="La chiamata è stata terminata, vuoi ricominciarla o tornare alla Home?"
        handleConfirm={() => {
          // Cancello i cookie di chiamata per chi chiude la chiamata
          stopCookieRefresh();
          terminateCall(neurosalesVideoCallUrl);
        }}
        handleClose={() => {
          stopCookieRefresh();
          terminateCall( store.reCommerceLink ?? `https://${store.hostKey}`);
        }}
        isConfirm={true}
        confirmText="Ricomincia chiamata"
        closeText="Torna alla home"
      />

      {/* <div
        className="overlay-protector"
        style={{ display: roomId && isVendor.current ? 'none' : 'block' }}
      ></div> */}
      <Draggable bounds="#videochat-draggable-area" handle={'.drag-here'}>
        <div
          className={clsx(
            'video-container',
            videoContainerFullscreen ? '' : videoContainerSize,
            videoContainerFullscreen && 'fullscreen'
          )}
        >
          {isSubscribed ? (
            <>
              <VideoChatComponent
                socket={socket}
                roomId={roomId.current}
                isVendor={isVendor.current ? true : false}
              />
              <VideoChatController
                handleChangeSize={handleVideoContainerSize}
                handleFullscreen={handleFullscreen}
                terminateCall={() => {
                  socket.emit('terminate-call', {
                    roomId: roomId.current,
                  });
                  stopCookieRefresh();

                  setIsCalled(false);
                  setIsCallTerminated(true);
                  isCallTerminatedRef.current = true;
                  setIsCallingOnProgress(false);
                  setIsCalling(false);
                }}
                infoMessage={videochatInfoMessage}
              />
            </>
          ) : (
            ''
          )}
          <>
            <div className="video-controller-call videocall-message">
              <div
                className="waiting-user drag-here"
                style={{ display: !isCallAvailable ? 'flex' : 'none' }}
              >
                <div>
                  <RecordVoiceOverIcon fontSize="small" />
                </div>
                <div>
                  In attesa{' '}
                  {isVendor.current
                    ? 'del cliente'
                    : 'del referente commerciale'}
                  ...
                </div>
              </div>
              <div
                className="established-connection drag-here"
                style={{ display: !isCallingOnProgress ? 'none' : 'flex' }}
              >
                <div>
                  <VerifiedUserIcon />
                </div>
              </div>
              {isVendor.current ? (
                <>
                  <div
                    id="makeCall"
                    style={{
                      display: !isCallAvailable || isCalling ? 'none' : 'flex',
                    }}
                    onClick={callClient}
                    className="videocall-message"
                  >
                    <div>
                      <VideoCallIcon fontSize="small" />
                    </div>
                    <div>Contatta il cliente!</div>
                  </div>
                  <div
                    className="waiting-user drag-here"
                    style={{
                      display:
                        isCalling && !isCallingOnProgress ? 'flex' : 'none',
                    }}
                  >
                    <div>
                      <VideoCallIcon fontSize="small" />
                    </div>
                    <div>Chiamata in corso...</div>
                  </div>
                </>
              ) : (
                <div
                  id="makeCall"
                  className="drag-here"
                  style={{
                    display:
                      isCallAvailable && !isCallingOnProgress ? 'flex' : 'none',
                  }}
                >
                  <div>
                    <ContactPhoneIcon fontSize="small" />
                  </div>
                  <div>Il referente commerciale ti contatterà a breve!</div>
                </div>
              )}
            </div>
            <div
              className="backToNeuro"
              onClick={() => {
                history.push('/');
              }}
            >
              TORNA IN NEUROSALES
            </div>
          </>
        </div>
      </Draggable>
      {isDisconnecting && !isCallTerminated ? (
        <div className="videocall-reconnection-message">
          <div>
            {`Il ${
              isVendor.current ? 'cliente' : 'referente commerciale'
            } si è disconnesso.`}
            <span className="dot-animated" />
            <span className="videocall-reconnection-timeout">{`Tra ${reconnectionTimeout} verrai reindirizzato all'inizio della videochiamata.`}</span>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  ) : (
    ''
  );
});

export default ClientComponent;
